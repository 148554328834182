// Navigation.js
import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <ul className="navbar">
        <li>
          <NavLink to="/">HEM</NavLink>
        </li>
        <li>
          <NavLink to="/om-oss">OM OSS</NavLink>
        </li>
        <li>
          <NavLink to="/tjanster">TJÄNSTER</NavLink>
        </li>
        <li>
          <NavLink to="/kontakt">KONTAKT</NavLink>
        </li>
        <li>
          <NavLink to="/utbildning">FÖRÄLDRAKURS</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
