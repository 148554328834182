import React from "react";
import Layout from "./Layout";
import { NavLink } from "react-router-dom";

const Education = () => {
  return (
    <Layout>
      <div className="main-div">
        <p className="under-title" id="education-under-title-top">
          FÖRÄLDRAKURS SELEKTIVT ÄTANDE
        </p>
        <div className="education-div" id="education-div-small">
          Tycker ditt barn bara om vissa livsmedel och visar ovilja att smaka på
          ny mat?
        </div>
        <div className="education-div" id="education-div-small">
          Blir det mycket bråk, tjat och frustration kopplat till måltiderna?
        </div>
        <div className="education-div" id="education-div-small">
          Känner du oro kring ditt barns ätande? Vill du träffa andra föräldrar
          i samma situation?
        </div>
        <div className="education-div" id="right-div">
          <p className="under-title" id="education-under-title">
            Kursens innehåll
          </p>
          <ul className="education-list">
            <li>Information om selektivt ätande och ätsvårigheter</li>
            <li>Anpassningar och strategier under måltider</li>
            <li>
              Råd om hur du kan göra för att utöka barnets matrepertoar och
              minska obehaget kring mat
            </li>
            <li>Möjlighet att ställa frågor till oss logopeder</li>
            <li>Möjlighet att träffa andra föräldrar i samma situation</li>
          </ul>
        </div>
        <div className="education-div">
          <p className="under-title" id="education-under-title">
            Praktisk information
          </p>
          <ul className="education-list" id="no-bullets-list">
            <li>
              <b>DATUM:</b>
              <br /> 19/2, 26/2 och 12/3
            </li>
            <li>
              <b>TID:</b>
              <br /> 13.00 - 14.30
            </li>
            <li>
              <b>PRIS:</b>
              <br />
              3500 kr för 3 tillfällen, 1,5 h per tillfälle.
            </li>
            <li>
              <b>ANMÄLAN:</b>
              <br /> Görs via kontaktformuläret här på hemsidan, du kan även
              klicka{" "}
              <NavLink to="/kontakt" className="link">
                {" "}
                HÄR
              </NavLink>
              .
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Education;
